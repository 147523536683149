/**********
 * Portfolio App : https://nazmi.grapheek.com 
 *********/

/* 
 * Petit reset 
 */
*,
*::before,
*::after {
    margin: 0; padding: 0;
    outline: 0; border: 0;
    box-sizing: border-box;
    background-repeat: no-repeat;
}

/* on rétablit l'outline pour l'accessibilité */
*:focus {
    outline: 2px dotted cyan;
}

/* on nettoie un peu en passant */
a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button,
label {
    cursor: pointer;
}
/* fin petit reset **********/

:root {
    --main-color: #00c0f3;
    --main-bg: rgb(217,80,125);
    --gradient-violet: linear-gradient(135deg, #d9507d 0%,#7f36fc 100%);
    --gradient-blue: linear-gradient(135deg, #7f36fc 0%,#129cb9 100%);
    --gradient-cyan: linear-gradient(135deg, #129cb9 0%, #21c4a0 100%);
    --gradient-green: linear-gradient(135deg, #21c4a0 0%,#7f36fc 100%);
    --gradient-yellow: linear-gradient(135deg, #7f36fc 0%,#d9507d 100%);
    --gradient-anthe: linear-gradient(135deg,rgba(255,78,0,1) 0%,rgba(229,19,86,1) 100%);
    --gradient-neeva: linear-gradient(135deg, #e5348a 0%,#ab1f67 100%);
    --main-font: azo-sans-web, sans-serif;
    --side-font: futura-pt-bold, sans-serif;
    --main-val: 1rem;
    --main-val2x: 2rem;
    --main-val4x: 4rem;
    --main-marge: 2rem;
    --app-height: 100vh;
}

html,
body,
#root {
    height: var(--app-height);
    overflow: hidden;
}

html {
    font-size: 87.5%;
    -webkit-text-size-adjust: 87.5%;
    -ms-text-size-adjust: 87.5%;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    background-image: var( --gradient-violet );
}

/* gradients */
.gradient, .screen { background-image: var( --gradient-violet ); }
.gradient-blue { background-image: var( --gradient-blue ); }
.gradient-cyan { background-image: var( --gradient-cyan ); }
.gradient-green { background-image: var( --gradient-green ); }
.gradient-yellow { background-image: var( --gradient-yellow ); }
.gradient-anthe { background-image: var( --gradient-anthe ); }
.gradient-neeva { background-image: var( --gradient-neeva ); }

/* texts */
.side-font,
h1,
h2,
.btn  {
    font-family: var(--side-font);
}

a {
    --link: var(--main-color);
    color: var(--link);
    fill: var(--link);
}

/* colors */
.col-main {
    color: var(--main-color);
}

.col-white {
    color: white;
}

/* utilities */
.upp {
    text-transform: uppercase;
}

.tac {
    text-align: center;
}

.mgr1 {
    margin-right: .5rem;
}

.mgl1 {
    margin-left: .5rem;
}

.dis-no {
    display: none;
}

.dis-ib {
    display: inline-block;
}

.bold {
    font-weight: 900;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* components */
.btn {
    background-color: var(--main-color);
    color: white;
    border-radius: var(--main-val4x);
    padding: var(--main-val) var(--main-val2x);
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    transition: all .1s ease;
}

.btn[target="_blank"]::after {
    content: '';
    background-image: url("../icons/link.svg");
    background-size: contain;
    height: 1rem;
    width: 1rem;
    display: inline-block;
    margin-left: .5rem;
}

.btn:hover {
    transform: translateY(2px);
    filter: sepia(10%);
}

/* header */
.skip-link,
.sr-only,
.hide {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.skip-link:focus {
    clip: auto;
    position: relative;
}

#masthead {
    position: fixed;
    z-index: 99;
    padding: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    pointer-events: none;
}

#masthead * {
    --link: white;
    pointer-events: all;
}

#masthead::before,
#masthead::after {
    content: '';
    background-image: url("../icons/rectangle.svg");
    background-repeat: repeat-y;
    background-size: 100% auto;
    width: 2rem;
    height: 100vw;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: top left;
    right: -2rem; top: 6rem;
    opacity: .3;
}

#masthead::after {
    height: .785714rem;
    --animation: run-y;
    animation: 1.6180s ease 0s infinite var(--animation);
}

#masthead .icons {
    display: flex;
}

#masthead .icons a + a {
    margin-left: var( --main-marge, 2rem );
}

.logo {
    font-size: var(--main-val2x);
    text-shadow: 0 .5rem 1rem rgba(0,0,0,0.15);
    line-height: 1;
}

.logo span {
    color: var(--main-color);
}

.social {
    height: 2rem;
    width: 2rem;
    background-size: contain;
    background-position: center;
}

#masthead .social:hover {
    animation: spin 1 .4s ease;
}

/* views */
.screens-wrapper {
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
    height: var(--app-height);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.screens-wrapper > * {
    scroll-snap-align: start;
}

.screen {
    z-index: 1;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
}

.screen-body {
    width: 100vw;
    height: var(--app-height);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.screen.inner-screens,
.inner-screens > .screen-body {
    height: auto;
}

/* nav */
.arrow {
    cursor: pointer;
    background-image: url("../icons/next.svg");
    background-size: contain;
    background-color: transparent;
    height: 2rem;
    width: 2rem;
    position: absolute;
    opacity: .5;
    filter: brightness(100);
}

.arrow:hover,
.nav-next {
    opacity: 1;
}

.nav-previous {
    top: var(--main-marge);
    transform: rotate(-90deg);
}

.nav-next {
    --animation: pulse-y;
    bottom: var(--main-marge);
    transform: rotate(90deg);
    animation: 1s ease 0s infinite var(--animation);
}

/* main screen arrows behind child screens bg */
.inner-screens :not(.slide) .arrow {
    z-index: 9;
}

/* remove unnecessary arrows */
.screens-wrapper > :first-child .nav-previous,
.screens-wrapper > :last-child .nav-next,
.inner-screens .slide:first-of-type .nav-previous,
.inner-screens .slide:last-of-type .nav-next {
    display: none;
}

/* elements */
h1 {
    font-size: 5rem;
    letter-spacing: -0.1rem;
    line-height: .95;
}

.content {
    font-size: 2rem;
    max-width: 44rem;
    margin: 2rem auto;
}

/* nav */
#skip {
    position: fixed;
    z-index: 99;
    right: 10rem;
    top: 0;
}

#skip li {
    display: inline-block;
}

.skip-link {
    color: #fff;
    clip: auto;
    position: relative;
    padding: 1rem;
    font-size: 1.2rem;
    background-color: transparent;
    display: inline-block;
}

/* Global Responsive */
@media screen and (min-width:1120px) {
    #skip {
        padding: 1rem;
        right: 0;
    }

    #masthead {
        width: 14.5rem;
        height: var(--app-height);
        flex-direction: column;
    }

    #masthead::after,
    #masthead::before {
        width: 2rem;
        height: var(--app-height);
        transform: none;
        right: 0;
        top: 0;
    }

    #masthead::after {
        height: .785714rem;
        --animation: run-x;
    }

    .screen-body {
        padding-left: 16rem;
        padding-right: 16rem; /* 2rem marge en plus du masthead width */
    }
}

@media screen and (max-width:968px) {
    h1 {
        font-size: 3rem;
        letter-spacing: -0.05rem;
    }

    .content {
        font-size: 1.5rem;
        margin: 1rem auto 1.5rem;
    }

    .btn {
        font-size: 1rem;
    }

    .btn[target="_blank"]::after {
        vertical-align: top;
    }
}

@media screen and (max-width:680px) {
    h1 {
        font-size: 2rem;
        letter-spacing: 0;
    }

    .content {
        font-size: 1rem;
    }

    .screen-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .nav-previous,
    .nav-next,
    .slide .nav-next {
        bottom: 4rem;
    }

    #skip {
        display: none;
    }
}

/* keyframes */
@keyframes run-x {
    from {
        top: 0;
    }
    to {
        top: 100%;
    }
}
@keyframes run-y {
    from {
        left: 0;
    }
    to {
        left: 100%;
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes pulse-x {
    from {
        margin-right: -0.25rem;
    }
    to {
        margin-right: 0;
    }
}
@keyframes pulse-y {
    from {
        margin-bottom: -0.25rem;
    }
    to {
        margin-bottom: 0;
    }
}
@keyframes fromRight {
    from {
        width: 0%; left: 100%; opacity: 0;
    }
    to {
        width: 100%; left: 0%; opacity: 1;
    }
}
@keyframes fromBottom {
    from {
        height: 0vh; top: 100%; opacity: 0;
    }
    to {
        height: var(--app-height); top: 0%; opacity: 1;
    }
}
